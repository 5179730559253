import React from "react";
import { StoreID } from "../../common/commonTypes";
import AndroidIcon from "../../components/icons/AndroidIcon";
import AppleIcon from "../../components/icons/AppleIcon";

const buttons = [
    { appType: StoreID.APP_STORE, name: "iOS", icon: <AppleIcon /> },
    { appType: StoreID.GOOGLE_PLAY, name: "Android", icon: <AndroidIcon /> },
    { appType: StoreID.UNIVERSAL, name: 'Universal', icon: ''},
];

const MenuAppTypeItem = ({ appType, name, icon, isActive, onClick }) => (
    <button className={isActive ? "active" : ""} onClick={onClick}>
        {icon}
        {name}
    </button>
);

const MenuAppType = ({ activeAppType, onSelect }) => {
    return (
        <div className="center-section" style={{maxWidth: '300px'}}>
            <div className="tab-buttons">
                {buttons.map((b) => (
                    <MenuAppTypeItem
                        appType={b.appType}
                        key={b.name}
                        name={b.name}
                        icon={b.icon}
                        isActive={b.appType === activeAppType}
                        onClick={onSelect.bind(null, b.appType)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MenuAppType;
