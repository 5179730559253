import React, {Component} from 'react';
import {Navigate} from "react-router-dom";
import {UserContext} from "../context/User";
import OffIcon from '../components/icons/OffIcon';

class LogoutButton extends Component {
    state = {
        pressed: false
    };

    onLogout(e) {
        e.preventDefault();
        this.setState({pressed: true});
    };

    getUser() {
        return this.context;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.pressed && this.state.pressed) {
            this.getUser().logout();
        }
    }

    render() {
        if (this.state.pressed) {
            return <Navigate to='/' />
        }

        // return (
        //     <a href="/#" className="right" onClick={this.onLogout.bind(this)}>Выйти</a>
        // );

        return ( 
        <div className="sidebar-item">
        <a
            href="/#"
            className="link"
            onClick={this.onLogout.bind(this)}
        >
            <div className="left-block">
                <div className="sidebar-item-icon"><OffIcon/></div>
                <h3>Выйти</h3>
            </div>
        </a>
    </div>)
    }

}
LogoutButton.contextType = UserContext;

export default LogoutButton;

