import React, {Component, useContext} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './css/style.css';
import './css/style2.css';
import {AdminRoutes} from "./pages/admin/AdminPortal";
import {User, UserContext, UserGroup} from "./context/User";
import MainMenu from "./widgets/MainMenu";
import {RestrictedArea} from "./wrappers/RestrictedArea";
import RouteList from "./pages/routes/RouteList";
import CreateEditRoute from "./pages/routes/CreateEditRoute";
import RouteStats from "./pages/routes/RouteStats";
import {CabinetRoutes} from "./pages/cabinet/CabinetPage";
import WarnTopPanel from "./widgets/WarnTopPanel";
import PushList from "./pages/push/PushList";
import CreateNewPush from "./pages/push/CreateNewPush";
import OrganicsList from "./pages/organics/OrganicsList";
import OrganicsStats from "./pages/organics/OrganicsStats";
import StartPage from "./pages/StartPage";
import PageContainer from "./pages/PageContainer";
import WalletExplorer from "./pages/special/WalletExplorer";
import FBTokens from "./pages/cabinet/FBTokens";
import AppList from "./pages/apps/AppList";
import Dashboard from "./components/layouts/Dashboard";
import "./components/index.css";

const InternalPage = () => {
    return <div>
        {/* <MainMenu/> */}
        <WarnTopPanel/>
        <Routes>
        <Route path="/" element={<Dashboard />}>
            <Route index element={<RouteList/>}/>
            <Route path='/newflow' element={<CreateEditRoute/>}/>
            <Route path='/editflow' element={<CreateEditRoute/>}/>
            <Route path='/organics' element={<OrganicsList/>}/>
            <Route path='/organicstats' element={<OrganicsStats/>}/>
            <Route path='/newpush' element={<CreateNewPush/>}/>
            <Route path='/apps' element={<AppList/>}/>
            <Route path='/fbpixel' element={<FBTokens/>}/>
            {/*[PUSH] <Route path='/push' element={<PushList/>}/>*/}
            {/*[PUSH] <Route path='/newpush' element={<CreateNewPush/>}*/}
            <Route path='/stats' element={<RouteStats/>}/>
            <Route path='/wallet' element={<WalletExplorer/>}/>
            {CabinetRoutes()}
            {AdminRoutes()}
        </Route>
        </Routes>
    </div>;
}

class App extends Component {
  render() {
    return (
        <User>
            <BrowserRouter>
                <RestrictedArea allowedTo={UserGroup.USER}>
                    <React.StrictMode>
                        <InternalPage/>
                    </React.StrictMode>
                </RestrictedArea>
                <RestrictedArea allowedToGroupOnly={UserGroup.GUEST}>
                    <Routes>
                        <Route path='/' element={<StartPage/>}/>
                    </Routes>
                </RestrictedArea>
            </BrowserRouter>
        </User>
    );
  }
}

export default App;
