import React, { Component } from 'react';
//import {Link} from "react-router-dom";
import Link from '../../widgets/Link';
import axios from "axios";
import './apps.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import queryString from 'query-string'
import {pick} from "../../util/util";
import {useLocation} from "react-router-dom";
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import MenuAppType from "../routes/MenuAppType";
import {StoreID} from "../../common/commonTypes";
import {UserContext} from "../../context/User";
import HamburgerIcon from '../../components/icons/HamburgerIcon';

const DEFAULT_APP_TYPE_FAV_KEY = 'app_type';
const DEFAULT_APP_TYPE = StoreID.APP_STORE;

const SortableAppItem = SortableElement(({ app }) =>
    <li className="sortable-item">
        <div className="drag-handle"><HamburgerIcon/></div>
        <div className="item-content">
            {/*<div className="priority">#{app.priority}</div>*/}
            <div className="item-name">{app.name}</div>
        </div>
    </li>
);

const SortableAppList = SortableContainer(({ apps, isDraggingDisabled  }) =>
    <ul>
        {apps.map((app, index) => (
            <SortableAppItem key={app.id} index={index} app={app} disabled={isDraggingDisabled} />
        ))}
    </ul>
);

const getChangedItems = (originalItems, newItems) => newItems.filter(newItem => {
    const originalItem = originalItems.find(item => item.id === newItem.id);
    return originalItem && originalItem.priority !== newItem.priority;
});

class AppList extends Component {
    state = {
        loading: true,
        updating: false,
        appType: DEFAULT_APP_TYPE,
        apps: []
    };

    componentDidMount() {
        const appType = this.getUser().getFavData(DEFAULT_APP_TYPE_FAV_KEY,DEFAULT_APP_TYPE);
        console.log('[componentDidMount] fetchData..');
        this.fetchData(appType);
        this.setState({ appType });
    }

    getUser() {
        return this.context;
    }

    async fetchData(appType) {
        this.setState({loading: true/*, deeplinks: []*/});

        let res = await axios.post('/applist', {appType});
        if (res.data && res.data.apps) {
            this.setState({loading: false,  apps: res.data.apps});
        }
    }

    async updateAppPriorities(apps) {
        apps = apps.map(app => pick(app, ['id','priority']));
        console.log('[updateApps]', apps);

        try {
            this.setState({updating: true});
            await axios.post('/app_update_priorities', {apps});
        } catch (e) {
            await this.fetchData(this.state.appType);
        } finally {
            this.setState({updating: false});
        }
    }

    onAppTypeSelect(value,e) {
        const appType = value;
        if (this.state.appType !== appType) {
            this.setState({appType});
            this.getUser().setFavData(DEFAULT_APP_TYPE_FAV_KEY, value, true);
            console.log('[onAppTypeSelect] fetchData..');
            this.fetchData(appType);
        }
    }

    render() {
        const {loading,updating,appType,apps} = this.state;

        const onSortEnd = ({ oldIndex, newIndex }) => {
            const newApps = arrayMove(apps, oldIndex, newIndex).map((app, index) => ({
                ...app, priority: index + 1,
            }));
            const changedApps = getChangedItems(apps,newApps);
            this.setState({apps: newApps});
            if (changedApps.length > 0) {
                this.updateAppPriorities(changedApps);
            }
        };
        const sortedApps = [...apps].sort((a, b) => a.priority - b.priority);

        return (
            <div className="page applist">
                <h1 style={{marginBottom: 16}}>Приложения</h1>

                <MenuAppType activeAppType={appType} onSelect={this.onAppTypeSelect.bind(this)}/>

                <div className='leftAlign' style={{margin: '25px 0'}}>
                    <h2>Приоритет приложений</h2>
                    <div className='tip'>В случае бана приложения потокам будет выставлено приложение первое из списка.</div>
                </div>
                {!loading && <SortableAppList apps={sortedApps} onSortEnd={onSortEnd} isDraggingDisabled={updating} />}

            </div>
        );
    }
}
AppList.contextType = UserContext;

export default props => <AppList location={useLocation()} {...props}/>;
