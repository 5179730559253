import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import {getResponseErrorsList, pick, randomInt} from "../../util/util";
import './admin.css';
import SelectClient from "../../widgets/admin/SelectClient";
import {useLocation, useNavigate} from "react-router-dom";
import Button from '../../components/UI/Button';

class CreateNewClient extends Component {
    state = {
        loading: true,
        name: "",
        telegram: '',
        admin_notes: '',
        contact_person: "",
        displayErrors: false,
        status: "",
        referrer_id: null
    };

    constructor(props){
        super(props);
        this.fetchData();
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.resetValues();
    }

    resetValues() {
        let pwdChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let pwdLen = randomInt(14,17);
        this.setState({ name: "Компания", login: "", password: Array(pwdLen).fill(pwdChars).map(x => x[Math.floor(Math.random() * x.length)] ).join('') });
    }

    getUser() {
        return this.context;
    }

    canChooseRefferer() {
        return this.getUser()?.hasPermission(UserGroup.MODERATOR);
    }

    async fetchData() {
        this.setState({loading: true});

        try {
            let res = await axios.post('/getdata', {data: ['refferers']});
            this.setState({loading: false, refferers: res.data ? res.data.refferers : []});
        } catch (e) {
            this.setState({loading: false, refferers: []});
        }
    }

    onSubmit(event) {
        event.preventDefault();

        if (!event.target.checkValidity()) {
            this.setState({ displayErrors: true, status: "" });
            return;
        }
        this.setState({ displayErrors: false, status: "" });

        const args = pick(this.state,['name','contact_person','telegram','admin_notes','login','password','referrer_id']);
        axios.post('/newclient', args).then((res) => {
            if (this.getUser().hasPermission(UserGroup.ADMIN)) {
                this.props.navigate("/admin/clientstats");
            } else {
                this.setState({status: "Клиент успешно зарегистрирован"});
                this.resetValues();
            }
            // TODO res.data;
        }).catch((err) => {
            this.setState({errors: getResponseErrorsList(err)});
        });
    }

    onFieldChanged(param,e) {
        this.setState({ errors: {}, [param]: e.target.value });
    }

    getError(param) {
        if (this.state.errors && param in this.state.errors)
            return this.state.errors[param];
        return "";
    }

    onReffererSelected(selectedReffererID) {
        this.setState({ referrer_id: selectedReffererID });
    }

    render() {
        const { loading, displayErrors, status, refferers, reffererID } = this.state;

        const renderError = (fieldName) => (
            <div className="error holder"><div className="message">{this.getError(fieldName)}</div></div>
        );

        const inputField = (fieldName,description,tip,optional) => (
            <li className="field">
                <div><label className={optional ? 'optional' : ''} htmlFor={fieldName}>{description}</label>
                    <div className={"tip" + (optional ? ' optional' : ' ')}>{tip}</div>
                </div>
                <div><input id={fieldName} name={fieldName} type="text" onChange={this.onFieldChanged.bind(this,fieldName)} required={!optional} value={this.state[fieldName]}/>
                    {renderError(fieldName)}
                </div>
            </li>
        );

        const selectRefferer = () => (
            <li className="field">
                <div><label className={'optional'}>Рефферер</label>
                    <div className={"tip optional"}>Кто пригласил<br/>нового клиента?</div>
                </div>
                <div>
                    <SelectClient
                        clients={refferers}
                        onChange={this.onReffererSelected.bind(this)}
                        isLoading={loading}
                        noneValue={'-- Без рефферера --'}
                        placeholder={'-- Без рефферера --'}
                    />
                </div>
            </li>


        );

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page create-new-client">
                <h1>Создание нового клиента</h1>
                <div className='center-section' style={{maxWidth: 480, marginTop: 15}}>
                    <div className='card'>
                        <form onSubmit={this.onSubmit} noValidate
                            className={displayErrors ? 'displayErrors' : ''}>
                            <ul>
                            {inputField("name","Название клиента", "Название компании или просто имя клиента")}
                            {inputField("contact_person","Имя представителя","Если это не указано в названии клиента", true)}
                            {inputField("telegram","Telegram акаунт")}
                            {inputField("admin_notes","Заметка для админа", "Необязательно. Видно только администратору", true)}
                            {inputField("login","Логин", "Основной аккаунт клиента для входа")}
                            {inputField("password","Пароль")}
                            {this.canChooseRefferer() && selectRefferer()}
                            <li className="submit">
                                <div>
                                    <div><Button title='Зарегистрировать' type='submit' style={{borderRadius: 6}}/></div>
                                    {renderError("common")}
                                </div>
                            </li>
                              {status && 
                                <li style={{margin: '0px'}}>
                                    <div style={{color: '#2c5db1'}}>{status}</div>
                                </li>}   
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            </RestrictedArea>
        );
    }
}
CreateNewClient.contextType = UserContext;


export default props => <CreateNewClient navigate={useNavigate()} {...props}/>;
